import React from 'react'
import Link from 'gatsby-link'
import get from 'lodash/get'
import { Flex, Box } from 'rebass/styled-components'
import styled, { css } from 'styled-components'
import breakpoint from '../lib/breakpoint'
import { A, P, H1, H2, H3, H4, Underline } from '../components/Type'
import Fade from 'react-reveal/Fade'
import Animation from '../components/Animation'
import Navigation from '../components/Navigation'
import { OnlyMobile, HideMobile, OffsetWrapper } from '../components/GridLayout'
import COLORS from '../components/colors'
import { SECTION } from '../components/Navigation'
import Moon from '../components/Moon'
import Layout from '../components/layout'

export const Grid = styled.div`
  padding: 6em 3em;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;


  ${breakpoint('tablet')`
    padding 10em 7em;
    display: grid;
    min-height: 80vh;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(4,1fr);
  `}

  /* Portrait iPad */
  @media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1) {
    padding 14em 3em;
  }

`

const GridChild = (props) =>
  <Box sx={{
    gridColumnStart: props.colStart,
    gridColumnEnd: props.colEnd,
    gridRowStart: props.rowStart,
    gridRowEnd: props.rowEnd
  }}
    {...props}
    />

class BlogIndex extends React.Component {
  render () {
    // const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    return (<Layout {...this.props}>
      <Box>
        <HideMobile>
          <Navigation color={COLORS.BEIGE} index={false} />
        </HideMobile>
        <Grid>
          <div style={{position: 'absolute', left: '40%', right: '28%', top: '12%', zIndex: -1}}>
            <Fade delay={250} bottom>
              <Moon />
            </Fade>
          </div>
          <GridChild colStart={1} colEnd={[13, 8]} rowStart={1} rowEnd={5}>
            <Fade delay={250} top>
              <div>
                <H3 style={{color: COLORS.BEIGE}}>The Forge 11th Hour</H3>
                <P style={{color: COLORS.BEIGE}}>We offer an intensive, fast turnaround service for clients looking to meet an immediate deadline. Whether it’s a big PR push, demo day, or ad campaign on the horizon — we work overtime to make sure you cross the finish line.
                Typical timelines are 24 hours to 1 week.</P>
                <P style={{color: COLORS.BEIGE}}>Call or text (929) 225-5533 and we’ll get back to you <span style={{'white-space': 'nowrap'}}>within the hour.</span></P>
              </div>
            </Fade>
          </GridChild>
          <GridChild colStart={[1, 9]} colEnd={13} rowStart={[6, 1]} rowEnd={5}>
            <Fade delay={250} bottom>
              <div style={{width: '85%'}}>
                <P style={{color: COLORS.MAUVE}}>The team at Forge are lifesavers! Despite the complexity of our product and challenging UI interactions, they ramped up in no time at all. If you’re looking for great UI design and thoughtful product thinking you’ll love working with them.</P>
                <P style={{color: COLORS.MAUVE, opacity: 0.3}}>Rebecca Buck<br />UX Manager<br />Amazon Web Services</P>
              </div>
            </Fade>
          </GridChild>
        </Grid>

      </Box>
      <Box sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        bg: 'midnight',
        zIndex: -2
      }} />
    </Layout>)
  }
}

export default BlogIndex
