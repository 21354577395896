import React from 'react'

const Moon = (props) =>
  <svg width='100%' height='100%' viewBox='0 0 477 477' version='1.1' xmlns='http://www.w3.org/2000/svg'>
    <g id='4-Framework' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' opacity='0.30'>
      <g id='Desktop-HD-Copy-4' transform='translate(-565.000000, -168.000000)' fill='#F68E58'>
        <g id='Group' transform='translate(565.000000, 168.000000)'>
          <circle id='Oval' opacity='0.400000006' cx='238.5' cy='238.5' r='238.5' />
          <path d='M155.155919,15 C116.277373,59.5237874 92.7201552,117.803637 92.7201552,181.592815 C92.7201552,321.434715 205.935321,434.798974 345.593306,434.798974 C355.884013,434.798974 366.03115,434.183464 376,432.987205 C337.091842,460.702156 289.507809,477 238.122217,477 C106.610948,477 0,370.248656 0,238.564201 C0,136.096863 64.5511053,48.7260982 155.155919,15 Z' id='Combined-Shape' />
        </g>
      </g>
    </g>
  </svg>

export default Moon
